import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import get from "lodash.get";

import { Box, Icon, styled, theme } from "@washingtonpost/wpds-ui-kit";
import Trending from "@washingtonpost/wpds-assets/asset/trending";

import { useContent } from "fusion-content";
import { useAppContext } from "fusion-context";
import { useBreakpoints } from "~/shared-components/BreakpointContext";
import { isTouchDevice } from "~/components/utilities/is-touch-device";
import { useResize } from "~/components/utilities/use-event-handlers";
import { useMultiView } from "~/shared-components/MultiViewContext";

import { hotTopicsDefaults, HotTopicsPropTypes } from "~/proptypes/hot-topics";
import { useAssemblerContext } from "~/shared-components/AssemblerContext";

const touchDeviceSel = "@media (pointer: coarse)";
const hoverDeviceSel = "@media (pointer: fine)";

const hideScrollBarSel = {
  "&::-webkit-scrollbar": {
    display: "none"
  },
  "-ms-overflow-style": "none",
  scrollbarWidth: "none"
};

const height = theme.space["300"]; // "48px"
const white = theme.colors.secondary;
const borderColor = theme.colors.gray400;
const linkColor = theme.colors.gray40;
const linkHoverColor = theme.colors.gray80;
const fontSize = theme.fontSizes["087"];
const lineHeight = 1;
const liColGap = theme.space["150"];
const labelPaddingLeft = theme.space["100"];
const ulColGap = theme.space["200"]; // reduced by 100 cuz label has padding 100
const ulColGapSm = theme.space["050"]; // reduced by 100 cuz label has padding 100

const Wrapper = styled(Box, {
  position: "sticky",
  top: "59px",
  zIndex: 3,

  backgroundColor: white,
  maxHeight: height,
  display: "flex",
  gap: `0 ${ulColGap}`,
  flexWrap: "nowrap",
  flexDirection: "row",
  overflow: "hidden",
  marginBottom: theme.space["200"],
  border: `1px solid ${borderColor}`,
  borderWidth: `1px 0`,
  "@sm": {
    position: "relative",
    zIndex: 0,
    top: 0,
    margin: 0,
    gap: `0 ${ulColGapSm}`
  },
  [touchDeviceSel]: {
    overflow: "scroll",
    ...hideScrollBarSel
  },
  a: {
    color: "inherit",
    "&:hover": {
      color: linkHoverColor
    }
  },
  variants: {
    isAdmin: {
      true: {
        zIndex: 0
      }
    },
    justify: {
      center: {
        justifyContent: "center",
        paddingRight: 0
      },
      normal: {
        justifyContent: "normal",
        paddingRight: "20vw" // This allows overscrolling, which is a nice effect
      }
    },
    hide: {
      true: {
        // NOTE: Only do this if not sticky which is @notSm
        "@notSm": {
          visibility: "hidden"
        }
      }
    }
  }
});

const Ul = styled("ul", {
  margin: 0,
  padding: 0,
  display: "flex",
  gap: liColGap,
  whiteSpace: "nowrap",
  color: linkColor,
  "&:last-child": {
    flexWrap: "wrap",
    [touchDeviceSel]: {
      flexWrap: "nowrap"
    },
    "li:last-child": {
      // NOTE: Cuz the labels have padding, padding to the last element assures centering will look centered
      paddingRight: labelPaddingLeft
    }
  }
});

const Li = styled("li", {
  listStyleType: "none",
  fontSize,
  lineHeight,
  padding: `${theme.space["100"]} 0`
});

// NOTE: Two vars for the gradient to the right of the label made of a slim solid block
// followed by a wider gradient so that when scrolling, the text fades away as it
// approaches the label text.
const blockW = theme.space["025"]; // 4px
const gradientHeight = `calc(1.25 * ${fontSize})`; // 1.25 and not 1 to cover descenders

const GradientLabel = styled("div", {
  position: "relative",
  display: "flex",
  fontSize,
  fontFamily: theme.fonts.subhead,
  fontWeight: theme.fontWeights.bold,
  lineHeight,
  padding: `${theme.space["100"]} 0`,
  paddingLeft: labelPaddingLeft,
  variants: {
    type: {
      live: {
        color: theme.colors.red100
      }
    },
    hide: {
      true: {
        [hoverDeviceSel]: {
          opacity: 0
        }
      }
    }
  },
  [touchDeviceSel]: {
    backgroundColor: white,
    position: "sticky",
    left: 0,
    // START: Gradient
    "&:before": {
      // NOTE: The solid part
      content: "",
      position: "absolute",
      right: `calc(-1 * ${blockW})`,
      backgroundColor: white,
      width: blockW,
      height: gradientHeight
    },
    "&:after": {
      // NOTE: The gradient part
      content: "",
      position: "absolute",
      right: `calc(-1 * ${liColGap})`,
      backgroundImage: `linear-gradient(to right, ${white}, transparent)`,
      width: `calc(${liColGap} - ${blockW})`,
      height: gradientHeight
    }
    // END: Gradient
  }
});

const HEADLINE_KEY = "headlines.basic";

/*
 * Cleanly harvests the custom fields needed to render each category
 *
 * @param {string} ns - i.e. the namespaec, one of "live" or "trending"
 * @param {object} customFields - the custom fields of the feature
 *
 * @returns {object} -- {
 *    {string} type - one of "live" or "trending"
 *    {string} id - live or trending websked id
 *    {string} label - the label text
 *  }
 *
 */
const getProps = (ns, customFields) => ({
  type: get(customFields, `${ns}Type`) || get(hotTopicsDefaults, `${ns}Type`),
  id: get(customFields, `${ns}WskId`) || get(hotTopicsDefaults, `${ns}WskId`),
  limit:
    get(customFields, `${ns}Limit`) ?? get(hotTopicsDefaults, `${ns}Limit`),
  label: get(customFields, `${ns}Label`) || get(hotTopicsDefaults, `${ns}Label`)
});

/*
 * Renders each item in the list of prism-promo items in each category
 *
 * @param {object} item - The prism-promo item
 *
 * @returns {func} -- the component
 *
 */
const Item = ({ item, index }) => {
  const headline = get(item, HEADLINE_KEY);
  if (!headline) return null;
  const href = get(item, "fusion_additions.links_to_use.basic");
  return (
    <Li data-link-detail={index + 1}>
      {React.createElement(
        href ? "a" : Fragment, // TODO: visited links
        { href },
        headline
      )}
    </Li>
  );
};

Item.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number
};

/*
 * Renders the pulsing dot to the left of the "live" label
 */
const PulsingDot = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="14"
    viewBox="0 0 64 100"
  >
    <circle cx="15" cy="50" r="15" fill="currentColor">
      <animate
        attributeName="r"
        from="15"
        to="20"
        dur="1s"
        begin="0s"
        repeatCount="indefinite"
      />
      <animate
        attributeName="opacity"
        from="1"
        to="0"
        dur="1s"
        begin="0s"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);

const StyledIcon = styled(Icon, {
  position: "relative",
  top: "1px",
  marginRight: theme.space["025"]
});

/*
 * Renders the trending icon
 */
const TrendingIcon = () => (
  <StyledIcon fill={theme.colors.gray40} size={fontSize}>
    <Trending />
  </StyledIcon>
);

/*
 * Renders the label associated with each category.
 *
 * @param {string} label - label text for the category
 * @param {string} type - one of "live" or "trending"
 *
 * @returns {func} -- the component
 *
 */
const Label = ({ label, type, hide }) => {
  if (!label) return null;
  return (
    <GradientLabel type={type} hide={hide}>
      {type === "live" && <PulsingDot />}
      {type === "trending" && <TrendingIcon />}
      {label}
    </GradientLabel>
  );
};

Label.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  hide: PropTypes.bool
};

/*
 * Given that (on non-touch devices) topics wrap out of site when the screen is too small,
 * this method hides (by making completely opaque) the label when the first topic in the
 * list wraps out of site so there isn't just a label with not topics following. A shortcoming
 * of this is that the overall bar will not appear centered
 *
 */
const handleHideLabel = (ref, setHideLabel) => {
  if (isTouchDevice()) return () => {};

  const ul = ref.current;

  const callback = (entries) => {
    entries.forEach((entry) => {
      const label = entry.target?.parentElement?.querySelector("div");
      if (label) setHideLabel(!entry.isIntersecting);
    });
  };

  const onIntersection = debounce(callback, 100);

  // eslint-disable-next-line compat/compat
  const observer = new IntersectionObserver(onIntersection);

  Array.from(ul.querySelectorAll("li:first-of-type")).forEach((li) => {
    observer.observe(li);
  });

  return () => observer.disconnect();
};

/*
 * Renders each category consisting of a label and list of items
 *
 * @param {string} label - string to show as label for the category
 * @param {array} items - list of prism-promo items
 * @param {string} type - one of "live" or "trending"
 *
 * @returns {func} -- the component
 *
 */
const Category = (props) => {
  const ref = useRef();
  const { label, items, type } = props;
  const [hideLabel, setHideLabel] = useState(false);

  useEffect(() => {
    return handleHideLabel(ref, setHideLabel);
  }, []);

  if (!items?.length) return null;

  return (
    <Ul ref={ref} data-link-group={`${type}-bar`}>
      {label && <Label hide={hideLabel} {...props} />}
      {items.map((item, i) => (
        <Item key={i} index={i} item={item} />
      ))}
    </Ul>
  );
};

Category.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.array
};

/*
 * This exists separately just to keep the HotTopics component "clean"
 *
 * @param {object} customFields - the custom fields of the feature
 *
 * @returns {array} - array of { _id, type , label, items } needed to render a Category
 *
 */
const useCategories = ({ customFields }) =>
  hotTopicsDefaults.listOfCategories
    .map((ns) => {
      const props = getProps(ns, customFields);
      const { type, id, limit, label } = props;

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const content = useContent(
        limit
          ? { source: "wsk-collection", query: { id, limit } }
          : { source: "no-content" }
      );

      let { items = [] } = content || {};
      items = items.filter((item) => get(item, HEADLINE_KEY));

      if (!items?.length) return null;

      const { _id } = content || {};
      return { _id, type, label, items };
    })
    .filter((content) => !!content);

/*
 * This exists separately just to keep the HotTopics component "clean"
 *
 * @param {object} ref - the ref created by useRef in HotTopics
 * @param {func} setJustify - the setJustify method created in HotTopics
 *
 */
const calcJustify = (ref, setJustify, forceNormalJustify) => {
  if (isTouchDevice()) {
    const w = ref?.current?.clientWidth || 0;
    // NOTE: Get width of all the uls
    const ulsW = Array.from(
      (ref?.current?.querySelectorAll && ref.current.querySelectorAll("ul")) ||
        []
    ).reduce((acc, ul) => (ul ? acc + (ul?.clientWidth || 0) : acc), 0);
    const diff = w - ulsW;
    setJustify(diff < 48 ? "normal" : "center");
  } else {
    setJustify(forceNormalJustify ? "force-normal" : "center");
  }
};

const handleHide = (bp, isAdmin, markerRef, setHide) => {
  const marker = markerRef.current;

  const callback = ([entry]) => {
    setHide(/xs/.test(bp) ? false : !entry.isIntersecting);
  };

  const onIntersection = debounce(callback, 300);

  // eslint-disable-next-line compat/compat
  const observer = new IntersectionObserver(onIntersection, {
    rootMargin: "3000px"
  });
  if (!isAdmin && marker) observer.observe(marker);
  return () => observer.disconnect();
};

/*
 * The main component of this feature
 *
 * @param {object} customFields - the custom fields of the feature
 *
 * @returns - the component
 */
const HotTopics = ({ customFields, id, ...rest }) => {
  const { hideOnWeb } = customFields;
  const markerRef = useRef();
  const ref = useRef();
  const { isAdmin } = useAppContext();
  const { isMobile } = useAssemblerContext();
  const { bp = "mx" } = useBreakpoints();
  const { activeTab, isMultiView, setHotTopics } = useMultiView();

  const categories = useCategories({ customFields });
  // NOTE: Harumph. Need different behavior in the admin at xs, hence force-normal
  const [forceNormalJustify, setForceNormalJustify] = useState(
    isAdmin && /xs/.test(bp)
  );
  const [justify, setJustify] = useState(() => {
    if (forceNormalJustify) return "force-normal";
    if (isMobile) return "normal";
    return "center";
  });
  const [hide, setHide] = useState(false);

  const adjust = useCallback(() => {
    calcJustify(ref, setJustify, forceNormalJustify);
  }, [forceNormalJustify]);
  useEffect(() => {
    adjust();
  }, [adjust, forceNormalJustify]);
  useResize(
    () => {
      adjust();
    },
    { interval: 100 }
  );

  useEffect(() => {
    setForceNormalJustify(isAdmin && /xs/.test(bp));
    return handleHide(bp, isAdmin, markerRef, setHide);
  }, [bp, isAdmin]);

  // Lift up hot topics so other tabs can use them.
  useEffect(() => {
    setHotTopics((existing) => {
      if (!existing[id])
        return {
          ...existing,
          [id]: <HotTopics customFields={customFields} id={id} {...rest} />
        };
      return existing;
    });
  }, [setHotTopics, id, customFields, rest]);

  if (
    hideOnWeb ||
    !categories.length ||
    (isMultiView && /foryou/.test(activeTab))
  )
    return null;

  const props = { ref, justify, hide, isAdmin };

  const TheBar = () => (
    <Wrapper {...props}>
      {categories.map((content) => {
        return content._id ? <Category key={content._id} {...content} /> : null;
      })}
    </Wrapper>
  );

  return (
    <Fragment>
      <div ref={markerRef} />
      <TheBar />
    </Fragment>
  );
};

HotTopics.label = "HotTopics";
HotTopics.propTypes = HotTopicsPropTypes;

export default HotTopics;
